import React from "react";
import posthog from "posthog-js";
import { uniqWith, isEqual } from "lodash";

import { Tooltip } from "@/components/atoms/Tooltip/Tooltip";
import { ExtraChartValueSchema } from "@/types/audience";
import { SummaryBoxProps } from "./SummaryBox";
import { SelectedForExclusionItem } from "@/hooks/useExclusionStateManager";
import Skeleton from "@/components/atoms/Skeleton/Skeleton";
import RowContent from "./RowContent";
import RowValueDisplay from "./RowValueDisplay";

export type SummaryRowProps = {
    summaryValue: ExtraChartValueSchema;
} & SummaryBoxProps;

const SummaryRow: React.FC<SummaryRowProps> = (props: SummaryRowProps) => {
    const { summaryValue, summary, exclusionStateManager, expand, allowExpand, isExpanding } = props;
    const { exclusionState, updateSelected, isExcluding } = exclusionStateManager;
    const { name, category } = summary;
    const { label, percentage, value, key } = summaryValue;
    const [isSelectedForExclusion, setIsSelectedForExclusion] = React.useState<boolean>(
        exclusionState.pending.some(s => s.category === (category ?? name) && s.value === key)
    );
    const isExcludingItem = React.useMemo(() => (exclusionState.running.some(s => s.category === category && s.value === key)),
        [exclusionState.running, category, key]
    );

    React.useEffect(() => {
        const changedValue: SelectedForExclusionItem = {
            category: category ?? name!,
            value: key!,
            label
        };
        if (isSelectedForExclusion) {
            updateSelected(uniqWith([
                ...exclusionState.pending,
                changedValue,
            ], isEqual));
        } else if (exclusionState.pending.some(s => isEqual(s, changedValue))) {
            updateSelected(exclusionState.pending.filter(p => !isEqual(p, changedValue)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSelectedForExclusion, summary, key]);

    React.useEffect(() => {
        setIsSelectedForExclusion(exclusionState.pending.some(s => s.category === (category ?? name) && s.value === key));
    }, [category, exclusionState, key, name]);

    const isOther = label === "Other";
    const isUnspecified = label === "Unspecified";

    const chartBackgroundColor = React.useMemo(() => {
        if (!percentage) return `linear-gradient(to right, #F3F5F6 2%, transparent 0%)`;

        const width = Math.max(2, percentage ?? 0.5) + "%";
        if (isExcludingItem) return `linear-gradient(to right, #8A9FA930 ${width}, #F3F5F6 0%)`;
        if (isSelectedForExclusion) return `linear-gradient(to right, #F9E3E4 ${width}, #FAEEEE 0%)`;

        return `linear-gradient(to right, #3366FF1A ${width}, transparent 0%)`;
    }, [isExcludingItem, isSelectedForExclusion, percentage]);

    const internalContent = React.useMemo(() => {
        const parsedLabel = isOther && isExpanding
            ? <Skeleton className="h-3 rounded-xl bg-gray-300 w-[100px]" />
            : label;

        const onClick = () => {
            if (isExcludingItem) return;
            
            if (isOther && allowExpand && !isExcluding && !isExpanding) {
                posthog.capture(`Expanded summary "${summary.name}"`);
                expand();
            } else if (!isOther && !isExcludingItem) {
                const action = isSelectedForExclusion ? "Unselected" : "Selected";
                posthog.capture(`${action} for exclusion "${summary.name}" item "${label}"`);
                setIsSelectedForExclusion(prev => !prev);
            }
        };

        return <RowContent
            onClick={onClick}
            chartBackgroundColor={chartBackgroundColor}
            parsedLabel={parsedLabel}
            parsedValue={<RowValueDisplay isOther={isOther} isExpanding={isExpanding} value={value} percentage={percentage} />}
            isOther={isOther}
            allowExpand={allowExpand}
            isExcluding={isExcluding}
            isExpanding={isExpanding}
            isUnspecified={isUnspecified}
            isExcludingItem={isExcludingItem}
            isSelectedForExclusion={isSelectedForExclusion}
            name={name}
        />
    }, [allowExpand, chartBackgroundColor, isExcludingItem, expand, isExcluding, isExpanding, isOther, isUnspecified, label, name, percentage, isSelectedForExclusion, summary.name, value]);

    const tooltipedContent = React.useMemo(() => (
        <Tooltip
            content={isExcluding ? "Wait until exclusion is finished to expand" : "Expand"}
            className="max-w-56 text-xs"
            side={isOther ? "bottom" : "right"}
        >
            {internalContent}
        </Tooltip>
    ), [internalContent, isExcluding, isOther]);

    if (isExcluding || !isOther || !allowExpand) return internalContent;

    return tooltipedContent;
};
export default SummaryRow;