import { useQueryParams, withDefault } from "use-query-params";

import { Main } from "@/components/organisms/Main";
import { Tabs } from "@/components/molecules/Tabs/Tabs";
import { useGetSubscriptionProductsQuery, useSubscriptionQuery } from "@/api/users";
import { Invoices } from "@/components/organisms/settings/invoices";
import { PlanPreview } from "@/components/organisms/settings/PlanPreview";
import { PaymentMethodInfo } from "@/components/organisms/settings/PaymentMethodInfo";
import { PaymentMethod } from "@/types/subscription";
import { useStatisticsQuery } from "@/api/audiences";

enum SettingsTabs {
    PLAN = "Your Plan",
    INVOICES = "Invoices",
}

const SettingsTabParam = withDefault(
    {
        encode(value: SettingsTabs) {
            return value === SettingsTabs.PLAN ? "plan" : value;
        },
        decode(strValue) {
            if (!Object.values(SettingsTabs).includes(strValue as SettingsTabs)) return SettingsTabs.PLAN;
            return strValue;
        },
    },
    SettingsTabs.PLAN,
);

export const Settings = () => {
    const [query, setQuery] = useQueryParams({ tab: SettingsTabParam }, { removeDefaultsFromUrl: true });
    const { data: statistics } = useStatisticsQuery(void true, { refetchOnMountOrArgChange: true });

    const { data: subscription } = useSubscriptionQuery(undefined);
    const { data: products } = useGetSubscriptionProductsQuery();

    return (
        <div className="min-h-full flex flex-col">
            <header className="py-6 pb-0 px-12 m-1.5  bg-white rounded-2xl shadow-main sticky z-10">
                <h1 className="flex-1 text-h1 text-ui-900 mb-5">Settings</h1>
                <Tabs
                    selectedTab={query.tab as string}
                    selectTab={option => setQuery({ tab: option })}
                    options={Object.values(SettingsTabs).map(value => ({ key: value, value }))}
                />
            </header>

            {query.tab === SettingsTabs.PLAN && subscription && products && statistics && (
                <Main>
                    <PlanPreview subscription={subscription} subscriptionProducts={products} statistics={statistics} />
                    <PaymentMethodInfo paymentMethod={subscription?.paymentMethod || ({} as PaymentMethod)} />
                </Main>
            )}

            {query.tab === SettingsTabs.INVOICES && <Invoices />}
        </div>
    );
};
