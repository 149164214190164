/* eslint-disable react-hooks/exhaustive-deps */
import CheckmarkIcon from "@/assets/icons/checkmark-outline.svg?react";
import React, { useMemo } from 'react';
import Skeleton from "../Skeleton/Skeleton";
import { ExclusionHookResponse } from "@/hooks/useExclusionStateManager";
import { Button } from "../Button/Button";
import { isNil } from "lodash";

type ExclusionControllerProps = {
    exclusionStateManager: ExclusionHookResponse;
}

const TIMER = 10;
export const ExclusionController = ({
    exclusionStateManager,
}: ExclusionControllerProps) => {
    const { isExcluding, updateSelected, runExclude, exclusionState, setIsTimerRunning } = exclusionStateManager;
    const { pending: selectedForExclusion, running: exclusionRunning, reseted } = exclusionState;
    const [count, setCount] = React.useState<number | undefined>(undefined);

    React.useEffect(() => {
        if (isNil(count)) setIsTimerRunning(false);
        else setIsTimerRunning(true);
    }, [count]);

    React.useEffect(() => {
        if (count === undefined || selectedForExclusion.length === 0) return;

        if (count < TIMER) {
            const timerId = setTimeout(() => setCount(count + 0.03), 30);
            return () => clearTimeout(timerId);
        } else if (selectedForExclusion.length > 0) {
            runExclude();
            setTimeout(() => setCount(undefined), 100);
        }
    }, [count, selectedForExclusion]);

    React.useEffect(() => {
        if (selectedForExclusion.length > 0) setCount(0);
    }, [selectedForExclusion]);

    const cancel = () => {
        updateSelected([]);
    }

    const runNow = () => {
        setCount(TIMER);
    }

    const pending = useMemo(() => {
        return (
            <div className="mb-2 ml-2 p-4 w-[400px] text-[14px] bg-ui-900 rounded-lg text-sm text-white">
                <div className="flex w-full">
                    <div className="font-semibold text-[16px] ">
                        Excluding Criteria
                    </div>
                </div>
                <div className="text-white mt-4">
                    <p>You selected {selectedForExclusion.length} criteria for exclusion.</p>
                </div>
                <div className="bg-ui-200 w-full rounded-md mt-4 flex justify-end">
                    <div className="h-1 bg-error-stroke rounded-md" style={{
                        width: `${((count ?? TIMER) / TIMER) * 100}%`,
                    }}></div>
                </div>
                <div className="flex w-full mt-3">
                    <span className="text-white">Excluding...</span>
                    <div className="ml-auto flex gap-2">
                        <Button
                            variant="basic"
                            size="custom"
                            className="ml-auto cursor-pointer text-ui-300 flex"
                            onClick={cancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="basic"
                            size="custom"
                            className="ml-auto cursor-pointer text-green-100 flex"
                            onClick={runNow}
                        >
                            <CheckmarkIcon className="mr-1" /> Estimate Now
                        </Button>
                    </div>
                </div>
            </div >);
    }, [count, selectedForExclusion]);

    const inProgress = useMemo(() => {
        return (
            <div className="mb-2 ml-2 p-4 w-[400px] text-[14px] bg-ui-900 rounded-lg text-sm text-white">
                <div className="flex w-full">
                    <div className="font-semibold text-[16px] ">
                        Criteria Excluded
                    </div>
                </div>
                <div className="text-white mt-4">
                    <p>{exclusionRunning.length} criteria excluded successfully. </p>
                </div>
                <Skeleton className="!bg-green-500 w-full h-1 mt-4" />
                <div className="flex w-full mt-3">
                    <span className="text-white">Updating the estimates...</span>
                </div>
            </div >);
    }, [exclusionRunning]);

    const handleKeyDown = () => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if(selectedForExclusion.length === 0) return;

            if (event.key === "Escape") {
                updateSelected([]);
            } else if (event.key === "Enter") {
                runExclude();
                setCount(undefined);
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    };

    React.useEffect(() => handleKeyDown(), [selectedForExclusion]);

    return <>
        <div
            data-visible={!reseted && isExcluding}
            className="fixed transition-all duration-300 ease-in-out bottom-0 left-0 translate-y-full data-[visible=true]:translate-y-0 z-[300]">
            {inProgress}
        </div>
        <div
            data-visible={selectedForExclusion.length > 0}
            className="fixed transition duration-300 ease-in-out bottom-0 left-0 translate-y-full data-[visible=true]:translate-y-0 z-[300]">
            {pending}
        </div >

    </>;
};
