import {
    AddressLocation,
    FilterDataTypes,
    FilterEntityTypes,
    FilterFields,
    FilterOperators,
    SourceCriteria,
} from "@primer/filters/types.ts";
import {
    Audience,
    AudienceEstimatePreview,
    AudiencePreview,
    AudienceShapeHeuristicsSchema,
    AudienceStatus,
    ChartSchema,
    Destination,
    DestinationsSchema,
    SummaryColumnName,
} from "./audience";
import { ScheduleFrequency, ScheduleWeekDays } from "./audience.ts";
import { Connection, ConnectionStatus } from "./integrations.ts";
import { CsvFile } from "./csv.ts";

export enum GetAudienceSortOrder {
    "ASC" = "ASC",
    "DESC" = "DESC",
}

export enum GetAudienceSortColumn {
    "NAME" = "name",
    "SIZE" = "size",
    "STATUS" = "status",
    "UPDATED_AT" = "updated_at",
    "NEXT_RUN_AT" = "next_run_at",
}

export enum CriteriaAssets {
    SIZE = "size",
    SUMMARY = "summary",
    PREVIEW = "preview",
}

export interface GetAudiencesFilters {
    status: AudienceStatus[];
    destinations: Destination[];
    inFavorites: boolean;
}

export interface GetAudiencesRequest {
    offset: number;
    limit: number;
    searchTerm: string;
    sort: GetAudienceSortColumn;
    order: GetAudienceSortOrder;
    filters: GetAudiencesFilters;
}

export interface GetAudienceRequest {
    id?: string;
}

export interface FavoriteAudienceRequest {
    audienceId: string;
    favorite: boolean;
}

export interface CreateAudienceRequest {
    name?: string;
    destinations?: DestinationsSchema;
    source_criteria?: SourceCriteria;
}

export interface CreateSchedule {
    frequency?: ScheduleFrequency;
    frequencyCount?: number;
    weekDays?: ScheduleWeekDays[];
    monthDay?: number;
}

export interface UpdateAudienceRequest {
    id: string;
    name?: string;
    schedule?: CreateSchedule;
    archived?: boolean;
    destinations?: DestinationsSchema;
}

export interface GetAudiencesResponse {
    paginationDetails: {
        totalCount: number;
        skippedCount: number;
        pageSize: number;
    };
    audiences: Audience[];
}

export type GetAudienceResponse = Audience & {
    destinations: DestinationsSchema;
    isAudienceRunForLatestAudienceShape: boolean;
};

export type GetCriteriaAssetsResponse = AudienceShapeHeuristicsSchema;

export type GetCriteriaAssetsRequest = {
    assets: CriteriaAssets[];
    summary_limit?: number;
    searchField?: string;
    searchValue?: string;
    audience_id?: string;
    shape_id?: string;
    offset: number;
};

export type GetEstimateSizeRequest = {
    audience_id?: string;
    shape_id?: string;
};
export type GetEstimateSizeRawResponse = {
    people_count?: string;
    companies_count?: string;
};
export type GetEstimateSizeResponse = Pick<AudienceShapeHeuristicsSchema, "people_count" | "companies_count">;

export type GetEstimatePreviewRequest = GetEstimateSizeRequest & {
    searchField?: string;
    searchValue?: string;
    offset?: number;
};
export type GetEstimatePreviewResponse = AudienceEstimatePreview;

export type GetEstimatePreviewRawResponse = {
    data: AudiencePreview[];
    count: string;
    offset?: number;
};

export type GetEstimateSummaryRequest = GetEstimateSizeRequest & {
    limit?: number;
    category?: SummaryColumnName;
};
export type GetEstimateSummaryResponse = ChartSchema;

export type SaveEstimateSummaryRequest = GetEstimateSizeRequest & {
    people_count?: number;
    companies_count?: number;
    preview?: AudienceEstimatePreview;
    heuristics?: {
        top?: GetEstimateSummaryResponse[];
    };
};
export interface DuplicateAudienceRequest {
    audienceId: string;
}

export interface DuplicateAudienceResponse extends GetAudienceResponse {}

export interface CreateAudienceResponse extends GetAudienceResponse {}

export interface CreateAudienceShapeResponse extends CreateAudienceResponse {}

export interface CreateAudienceShapeRequest {
    updateEstimate: boolean;
    audienceId: string;
    source_criteria?: SourceCriteria;
    enrichment_criteria?: {
        categories: AudiencesEnrichmentCriteria[];
    };
}
export interface GetAudienceShapeEstimateHeuristicsRequest {
    shapeId?: string;
    audienceId?: string;
}

export enum AudiencesEnrichmentCriteria {
    AD = "ad-enrichment",
}

export interface RunAudienceRequest {
    audienceId: string;
}

export interface RerunAudienceRequest {
    audienceId: string;
    runId: string;
}

export interface GetRecentlyUsedFiltersReponse {
    field: FilterFields | string;
    entity_type: FilterEntityTypes;
}

export interface GetFieldValuesRequest {
    fieldId: FilterFields | string;
    q: string;
}
export interface FindFieldValuesRequest {
    fieldId: FilterFields | string;
    values: (string | number | AddressLocation)[];
}
export interface GetFieldValuesResponse {
    value: string;
    label: string;
    category?: string;
}
export interface FindFieldValuesResponse {
    value: string;
    label: string;
    category: string;
}

export type GetConnectionsResponse = Connection[];

export type GetConnectionsRequest = {
    enabled?: boolean;
    state?: ConnectionStatus;
};

export interface CreateOAuthConnection {
    code: string;
    provider: string;
}
export interface GetAudienceRunsRequest {
    offset: number;
    limit: number;
    audienceId: string;
}

export interface User {
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
}

export enum AudienceSyncStatus {
    RECEIVED = "Received",
    PROCESSED = "Processed",
    FINISHED = "Finished",
}

export interface AudienceSync {
    status: AudienceSyncStatus;
    destination: Destination;
    id: number;
    createdAt: string;
    destinationAudienceId?: string | null;
    finishedAt?: string | null;
    error?: string | null;
    adAudienceUrl?: string | null;
}

export interface AudienceRun {
    id: string;
    createdAt: string;
    buildRequestedAt: string;
    syncs?: AudienceSync[] | null;
    createdBy?: User | null;
    companiesCount?: number | null;
    peopleCount?: number | null;
    builtAt?: string | null;
    buildError?: string | null;
    finishedAt?: string | null;
}

export interface GetAudienceRunsResponse {
    totalCount: number;
    data: AudienceRun[];
}

export interface PostHogPersonProperties {
    ClerkUserId?: string;
    email?: string;
    name?: string; // like John Smith
    ClerkOrganizationId?: string;
    CompanyName?: string;
    CompanyWebsite?: string; // like sayprimer.com
}

type AudienceShapeEstimateHeuristicsChartValue = {
    key: string;
    label: string;
    value: number;
};

type AudienceShapeEstimateHeuristicsChart = {
    name: string;
    total: number;
    values: AudienceShapeEstimateHeuristicsChartValue[];
};

export type AudienceShapeEstimateHeuristics = {
    summary?: AudienceShapeEstimateHeuristicsChart[];
    limit?: number;
    top?: AudienceShapeEstimateHeuristicsChart[];
};

export type AudienceShapeEstimates = {
    id: string;
    audience_id: string;
    shape_id: string;
    companies_count: number;
    people_count?: number;
    preview_s3_key?: string;
    destinations_match?: object;
    heuristics?: AudienceShapeEstimateHeuristics;
    errors?: object;
    created_at: Date;
    finished_at: Date;
    started_at: Date;
    duration_ms: number;
};

export type FirstPartyFilterResponseItem = {
    id: string;
    name?: string | null;
    dataType: FilterDataTypes;
    objectType: string;
    instanceId: string;
    allowedOperators: FilterOperators[];
    path?: string;
    metadata?: {
        picklist: string[];
    };
};

export type GetFirstPartyFiltersResponse = FirstPartyFilterResponseItem[];

export interface GetFirstPartyFiltersRequest {
    instanceId: string;
}

export enum CrmSyncDestinationName {
    CLICKHOUSE = "clickhouse",
}

export enum CrmSyncSource {
    SALESFORCE = "salesforce",
}

export enum CrmSyncStatus {
    ACTIVE = "active",
    INACTIVE = "inactive",
}

export interface CrmSyncRequest {
    instanceId: string;
}

export default interface CrmSync {
    id: string;
    createdAt: string;
    updatedAt: string;
    destination: CrmSyncDestinationName;
    source: CrmSyncSource;
    instanceId: string;
    organizationId: number;
    organizationName: string;
    connectionId: string;
    origin: string;
    requester?: object; // any metadata
    status: CrmSyncStatus;
    isSyncing: boolean;
    latestSyncJobCreatedAt?: string;
    latestSyncJobSynchronizedAt?: string;
    latestSyncJobStatus?: string;
    error?: null | string;
}

export interface Onboarding {
    id: string;
    organization_id: string;
    user_id: string;
    audience_id: string;
    audience_run: boolean;
    crm_connected: boolean;
    add_platform_connected: boolean;
    onboarding_completed: boolean;
    ad_destination_visited: boolean;
    hub_closed: boolean;
    created_at: string;
    updated_at: string;
}

export interface UpdateOnboardingRequest {
    id: string;
    audience_id?: string;
    audience_run?: boolean;
    crm_connected?: boolean;
    add_platform_connected?: boolean;
    onboarding_completed?: boolean;
    ad_destination_visited?: boolean;
    hub_closed?: boolean;
}

export interface GetImportedDatasetsRequest {
    offset?: number;
    limit?: number;
}

export type getImportedDatasetsResponse = CsvFile[];
