import { useEffect, useState } from "react";
import { usePostHog } from "posthog-js/react";

export enum FeatureFlagsEnum {
    SUMMARY = "pitch-summary-shipped",
    PREVIEW = "pitch-preview-shipped",
    ONBOARDING_PITCH = "pitch-10-2-onboarding",
    CSV_IMPORT = "pitch-9-2-19-csv-import",
}

export const useFeatureFlag = (flag: string, defaultEnabled: boolean = false) => {
    const posthog = usePostHog();

    const [featureEnabled, setFeatureEnabled] = useState<boolean | undefined>(posthog.isFeatureEnabled(flag));

    useEffect(() => {
        return posthog.onFeatureFlags(() => {
            setFeatureEnabled(posthog.isFeatureEnabled(flag));
        });
    }, [posthog, flag]);

    if (featureEnabled === undefined) return defaultEnabled;
    return featureEnabled;
};
