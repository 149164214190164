/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, SetStateAction } from "react";
import { sortBy, indexOf } from "lodash";
import { ExtraChartSchema, SummaryMetricColumn } from "@/types/audience";
import { createOthers } from "@/utils/heuristics";
import { parseFilterValueLabel } from "@/utils/string";
import { CriteriaEstimateManagerHook } from "./useCriteriaAssetsManager";

export const summaryCategoriesOrdered: string[] = [
    SummaryMetricColumn.JOB_TITLE,
    SummaryMetricColumn.SENIORITY,
    SummaryMetricColumn.COMPANY_DOMAIN,
    SummaryMetricColumn.ANNUAL_REVENUE,
    SummaryMetricColumn.HEADCOUNT,
    SummaryMetricColumn.COMPANY_LOCATION,
    SummaryMetricColumn.INDUSTRY,
];
const personSummaries: string[] = [
    SummaryMetricColumn.JOB_TITLE,
    SummaryMetricColumn.SENIORITY,
    SummaryMetricColumn.COMPANY_DOMAIN,
];

export interface SummaryStateResponse {
    summaryCategoriesOrdered: string[];
    summaryValues: ExtraChartSchema[];
    setSummaryValues: Dispatch<SetStateAction<ExtraChartSchema[]>>;
}

export function useSummary({ criteriaAssets, isLoadingSize }: CriteriaEstimateManagerHook): SummaryStateResponse {
    const [summaryValues, setSummaryValues] = React.useState<ExtraChartSchema[]>([]);

    React.useEffect(() => {
        const withPercentage: ExtraChartSchema[] =
            criteriaAssets?.heuristics?.top?.map(category => {
                const topSummaryTotal = category.total;
                const audienceEstimateTotal = personSummaries.includes(category?.name ?? "")
                    ? criteriaAssets?.people_count
                    : criteriaAssets?.companies_count;
                const total: number = topSummaryTotal ?? audienceEstimateTotal ?? 1;

                return {
                    category: category.category,
                    name: category.name,
                    total,
                    limit: category.limit,
                    allowExpand: (!category.limit || category.limit < 100) && category.values?.length >= 15,
                    values: category.values.map(item => ({
                        ...item,
                        label: parseFilterValueLabel(
                            String(item.label).toLowerCase(),
                            item.label,
                            category?.name?.toLowerCase(),
                        ),
                        percentage: isLoadingSize ? undefined : (item.value * 100) / total,
                    })),
                };
            }) ?? [];

        const namesToBeRemoved = getNamesToBeRemoved(withPercentage);
        const withoutExtraLocations = withPercentage?.filter(
            n => !namesToBeRemoved.includes(n?.name as SummaryMetricColumn),
        );
        const withOthers = withoutExtraLocations.map(v => createOthers(v)).filter(v => !!v);

        const sorted = sortBy(withOthers, category => {
            const index = indexOf(summaryCategoriesOrdered, category.name);
            return index === -1 ? Number.MAX_SAFE_INTEGER : index;
        });
        setSummaryValues(sorted);
    }, [criteriaAssets]);

    const getNamesToBeRemoved = (withPercentage: ExtraChartSchema[] = []): SummaryMetricColumn[] => {
        const options = [
            SummaryMetricColumn.COMPANY_LOCATION,
            SummaryMetricColumn.COMPANY_COUNTRY,
            SummaryMetricColumn.COMPANY_STATE,
            SummaryMetricColumn.COMPANY_CITY,
        ];
        const country = withPercentage?.find(w => w.name === SummaryMetricColumn.COMPANY_COUNTRY);
        const state = withPercentage?.find(w => w.name === SummaryMetricColumn.COMPANY_STATE);
        const hasCity = withPercentage?.some(w => w.name === SummaryMetricColumn.COMPANY_CITY);

        if (country && !country?.values?.some(v => v.percentage && v.percentage > 80))
            return options.filter(o => o !== SummaryMetricColumn.COMPANY_COUNTRY);
        if (state && !state?.values?.some(v => v.percentage && v.percentage > 80))
            return options.filter(o => o !== SummaryMetricColumn.COMPANY_STATE);
        if (hasCity) return options.filter(o => o !== SummaryMetricColumn.COMPANY_CITY);

        return options.filter(o => o !== SummaryMetricColumn.COMPANY_LOCATION);
    };

    return {
        summaryCategoriesOrdered,
        summaryValues,
        setSummaryValues,
    };
}
