/* eslint-disable react-hooks/exhaustive-deps */
import {
    CriteriaAssets,
    GetAudienceResponse,
    GetEstimatePreviewRawResponse,
    GetEstimatePreviewRequest,
    GetEstimatePreviewResponse,
    GetEstimateSizeRawResponse,
    GetEstimateSizeRequest,
    GetEstimateSizeResponse,
    GetEstimateSummaryRequest,
    GetEstimateSummaryResponse,
    SaveEstimateSummaryRequest,
} from "@/types/api";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import {
    useGetCriteriaEstimatePreviewMutation,
    useGetCriteriaEstimateSizeMutation,
    useGetCriteriaEstimateSummaryMutation,
    useSaveEstimateMutation,
} from "@/api/criteriaAssets";
import { differenceInHours } from "date-fns";
import { AudienceEstimatePreview, ChartSchema, ExtraChartSchema, SummaryColumnName } from "@/types/audience";
import { isEqual, omit, uniqBy, size as getSize, merge } from "lodash";
import { parseSummary } from "@/utils/exclusion";
import { usePostHog } from "posthog-js/react";

export type CriteriaEstimateManagerHook = {
    size: GetEstimateSizeResponse | undefined;
    preview: AudienceEstimatePreview | undefined;
    summaries: ExtraChartSchema[];
    criteriaAssets?: JoinedCriteriaAssets;
    isLoadingSomeEstimateAsset: boolean;
    isLoadingSize: boolean;
    isLoadingPreview: boolean;
    isLoadingSomeSummary: boolean;
    summariesLoading: string[];
    summariesExpanding: string[];
    isExcluding: boolean;
    isSlowLoading: boolean;
    callEstimatePreview: (body?: Partial<GetEstimatePreviewRequest>) => Promise<void>;
    callAllEstimates: (
        body: Partial<GetEstimateSizeRequest>,
        overrides?: RequestOverrides,
        isExcluding?: boolean,
    ) => Promise<[void, void, void[]]>;
    expandSummary: (body: Partial<GetEstimateSummaryRequest>) => void;
};

export type RequestOverrides = {
    previewParams?: Partial<GetEstimatePreviewRequest>;
    summaryParams?: {
        limits: { [key in SummaryColumnName]?: number };
    };
};

export type JoinedCriteriaAssets = {
    heuristics?: {
        top: ChartSchema[] | undefined;
    };
    preview?: AudienceEstimatePreview | undefined;
    companies_count?: number | undefined;
    people_count?: number | undefined;
};

export const useCriteriaAssetsManager = ({
    audienceId,
    shapeId,
    audience,
}: {
    audienceId?: string;
    shapeId?: string;
    audience?: GetAudienceResponse;
}): CriteriaEstimateManagerHook => {
    const { id } = useParams();
    const posthog = usePostHog();

    const [lastStoredCriteriaAssets, setLastStoredCriteriaAssets] = useState<JoinedCriteriaAssets | undefined>();
    const [size, setSize] = useState<GetEstimateSizeResponse | undefined>();
    const [isLoadingSize, setIsLoadingSize] = useState(false);
    const [preview, setPreview] = useState<GetEstimatePreviewResponse | undefined>();
    const [isLoadingPreview, setIsLoadingPreview] = useState(false);
    const [isExcluding, setIsExcluding] = useState(false);
    const [summariesLoading, setSummariesLoading] = useState<SummaryColumnName[]>([]);
    const [summariesExpanding, setSummariesExpanding] = useState<SummaryColumnName[]>([]);
    const [rawSummaries, setRawSummaries] = useState<GetEstimateSummaryResponse[] | undefined>([]);
    const [summaries, setSummaries] = useState<ExtraChartSchema[]>([]);
    const [isSlowLoading, setIsSlowLoading] = useState(false);

    const [getCriteriaEstimateSizeMutation] = useGetCriteriaEstimateSizeMutation();
    const getCriteriaEstimateSize = async (req: GetEstimateSizeRequest): Promise<GetEstimateSizeRawResponse> =>
        getCriteriaEstimateSizeMutation(req).unwrap();

    const [getCriteriaEstimatePreviewMutation] = useGetCriteriaEstimatePreviewMutation();
    const getCriteriaEstimatePreview = async (req: GetEstimatePreviewRequest): Promise<GetEstimatePreviewRawResponse> =>
        getCriteriaEstimatePreviewMutation(req).unwrap();

    const [getCriteriaEstimateSummaryMutation] = useGetCriteriaEstimateSummaryMutation();
    const getCriteriaEstimateSummary = async (req: GetEstimateSummaryRequest): Promise<GetEstimateSummaryResponse[]> =>
        getCriteriaEstimateSummaryMutation(req).unwrap();

    const [saveEstimateMutation] = useSaveEstimateMutation();
    const saveEstimate = async (req: SaveEstimateSummaryRequest): Promise<void> => saveEstimateMutation(req).unwrap();

    useEffect(() => loadSize(), [size, audienceId, audience]);
    useEffect(() => loadPreview(), [preview, audienceId, audience]);
    useEffect(() => loadSummaries(), [rawSummaries, audienceId, audience]);
    useEffect(
        () =>
            setSummaries(
                parseSummary(isLoadingSize, isExcluding, rawSummaries, size?.people_count, size?.companies_count),
            ),
        [isLoadingSize, isExcluding, rawSummaries, size],
    );
    useEffect(
        () =>
            setLastStoredCriteriaAssets(
                audience?.shape?.heuristics
                    ? {
                          people_count: audience.shape.heuristics?.people_count,
                          companies_count: audience.shape.heuristics?.companies_count,
                          preview: audience.shape.heuristics?.preview,
                          heuristics: {
                              top: audience.shape.heuristics?.heuristics?.top,
                          },
                      }
                    : undefined,
            ),
        [audience],
    );

    const safeRequestIdTracker = useRef({
        size: 0,
        preview: 0,
        summaries: {
            [SummaryColumnName.ANNUAL_REVENUE]: 0,
            [SummaryColumnName.COMPANY_LOCATION]: 0,
            [SummaryColumnName.COMPANY_DOMAIN]: 0,
            [SummaryColumnName.HEADCOUNT]: 0,
            [SummaryColumnName.INDUSTRY]: 0,
            [SummaryColumnName.JOB_TITLE]: 0,
            [SummaryColumnName.SENIORITY]: 0,
        },
    });

    const loadSize = () => {
        if (!size && ((!audienceId && !id) || audience)) {
            if (isOldEstimate()) callEstimateSize();
            else {
                posthog.capture("Reused Estimate", {
                    asset: CriteriaAssets.SIZE,
                    audienceId: audience?.id,
                    shapeId: audience?.shape?.id,
                    lastPulled: audience?.shape?.heuristics?.created_at,
                });
                setSize(audience?.shape?.heuristics);
            }
        }
    };

    const loadPreview = () => {
        if (!preview && !!((!audienceId && !id) || audience)) {
            const hasSizeButNoPreview =
                audience?.shape?.heuristics?.people_count && !getSize(audience?.shape?.heuristics?.preview?.data);
            const previewHasFilter =
                audience?.shape?.heuristics?.people_count &&
                Number(audience?.shape?.heuristics?.preview?.count || 0) < audience?.shape?.heuristics?.people_count;
            const previewNotOnFirstPage =
                getSize(audience?.shape?.heuristics?.preview?.data) &&
                (audience?.shape?.heuristics?.preview?.offset || 0) > 0;

            if (
                isOldEstimate() ||
                !audience?.shape?.heuristics?.preview ||
                hasSizeButNoPreview ||
                previewHasFilter ||
                previewNotOnFirstPage
            )
                callEstimatePreview();
            else {
                posthog.capture("Reused Estimate", {
                    asset: CriteriaAssets.PREVIEW,
                    audienceId: audience.id,
                    shapeId: audience.shape?.id,
                    lastPulled: audience?.shape?.heuristics?.created_at,
                });
                setPreview(audience?.shape?.heuristics?.preview);
            }
        }
    };

    const loadSummaries = () => {
        if (!rawSummaries?.length && ((!audienceId && !id) || audience)) {
            const emptyOrOldSummary =
                isOldEstimate() ||
                !audience?.shape?.heuristics?.heuristics?.top?.length ||
                audience?.shape?.heuristics?.heuristics?.top?.some(t => !t.category) ||
                audience.shape.heuristics.heuristics.top.length < Object.values(SummaryColumnName).length;

            if (emptyOrOldSummary) callAllSumaries();
            else {
                posthog.capture("Reused Estimate", {
                    asset: CriteriaAssets.SUMMARY,
                    audienceId: audience?.id,
                    shapeId: audience?.shape?.id,
                    lastPulled: audience?.shape?.heuristics?.created_at,
                });
                setRawSummaries(audience?.shape?.heuristics?.heuristics?.top);
                const unexpanded = audience?.shape?.heuristics?.heuristics?.top?.filter(
                    t => t.category && (!t.limit || (t.limit < 100 && t.values?.length === t.limit)),
                );
                unexpanded?.forEach(({ category }) => {
                    expandSummary({
                        audience_id: audienceId,
                        shape_id: shapeId,
                        category: category as SummaryColumnName,
                    });
                });
            }
        }
    };

    const isOldEstimate = () => {
        return (
            !audience?.shape?.heuristics?.finished_at ||
            differenceInHours(new Date(), audience?.shape?.heuristics?.finished_at) > 11
        );
    };

    const callEstimateSize = async (body?: Partial<GetEstimateSizeRequest>) => {
        setIsLoadingSize(true);
        const safeRequestId = new Date().getTime();
        safeRequestIdTracker.current.size = safeRequestId;
        const result = await getCriteriaEstimateSize({
            audience_id: id,
            shape_id: shapeId,
            ...body,
        });
        if (safeRequestIdTracker.current.size === safeRequestId) {
            setSize({
                people_count: Number(result.people_count),
                companies_count: Number(result.companies_count),
            });
            setIsLoadingSize(false);
            safeRequestIdTracker.current.size = 0;
        }
    };

    const callEstimatePreview = async (body?: Partial<GetEstimatePreviewRequest>) => {
        setIsLoadingPreview(true);
        const safeRequestId = new Date().getTime();
        safeRequestIdTracker.current.preview = safeRequestId;
        const result = await getCriteriaEstimatePreview({
            audience_id: id,
            shape_id: shapeId,
            ...body,
        });
        if (safeRequestIdTracker.current.preview === safeRequestId) {
            setPreview({
                data: result.data,
                count: result.count,
                offset: Number(result.offset),
            });
            setIsLoadingPreview(false);
            safeRequestIdTracker.current.preview = 0;
        }
    };

    const callEstimateSummary = async (body: Partial<GetEstimateSummaryRequest>, expand: boolean = false) => {
        if (!body.category) return;

        const requestedCategory: SummaryColumnName = body.category;
        const setLoadingStateFunction = expand ? setSummariesExpanding : setSummariesLoading;
        setLoadingStateFunction(prev => {
            prev.push(requestedCategory);
            return prev;
        });
        const safeRequestId = new Date().getTime();
        safeRequestIdTracker.current.summaries[requestedCategory] = safeRequestId;
        const result = await getCriteriaEstimateSummary({
            audience_id: id,
            shape_id: shapeId,
            ...body,
        });
        if (safeRequestIdTracker.current.summaries[requestedCategory] === safeRequestId) {
            setRawSummaries(prev => uniqBy([...result, ...(prev ?? [])], "category"));
            setLoadingStateFunction(prev => prev.filter(p => p !== requestedCategory));
            safeRequestIdTracker.current.summaries[requestedCategory] = 0;

            if ((!body.limit || body.limit < 100) && result.some(r => r.values.length === 15)) {
                expandSummary(body);
            }
        }
    };

    const expandSummary = (body: Partial<GetEstimateSummaryRequest>) => {
        callEstimateSummary(
            {
                ...body,
                limit: 100,
            },
            true,
        );
    };

    const callAllSumaries = (
        body?: Partial<GetEstimateSummaryRequest>,
        limits?: { [key in SummaryColumnName]?: number },
    ) => {
        return Promise.all(
            Object.values(SummaryColumnName).map(category => {
                callEstimateSummary({ ...body, category, limit: limits?.[category] ?? 15 });
            }),
        );
    };

    const callAllEstimates = async (
        body: Partial<GetEstimateSizeRequest>,
        overrides: RequestOverrides = {},
        isExcluding: boolean = false,
    ) => {
        setIsExcluding(isExcluding);
        return Promise.all([
            callEstimateSize(body),
            callEstimatePreview(merge(body, overrides?.previewParams)),
            callAllSumaries(body, overrides.summaryParams?.limits),
        ]);
    };

    const isLoadingSomeSummary = useMemo(() => summariesLoading.length > 0, [summariesLoading]);

    const isLoadingSomeEstimateAsset = useMemo(
        () => isLoadingSize || isLoadingPreview || isLoadingSomeSummary,
        [isLoadingSize, isLoadingPreview, isLoadingSomeSummary],
    );

    useEffect(() => {
        if (!isLoadingSomeSummary) setIsExcluding(false);
    }, [isLoadingSomeSummary]);

    const criteriaAssets = useMemo(
        () =>
            !!size || !!preview || (!!rawSummaries?.length && rawSummaries?.length > 0)
                ? {
                      people_count: size?.people_count,
                      companies_count: size?.companies_count,
                      ...{ preview: preview },
                      ...{
                          heuristics: {
                              top: rawSummaries,
                          },
                      },
                  }
                : undefined,
        [size, preview, rawSummaries],
    );

    useEffect(() => {
        const estimateFields = omit(lastStoredCriteriaAssets, "finished_at");
        if (
            audienceId &&
            shapeId &&
            summariesLoading.length === 0 &&
            rawSummaries?.length &&
            rawSummaries?.length > 0 &&
            summariesExpanding.length === 0 &&
            rawSummaries?.length &&
            rawSummaries?.length > 0 &&
            !isLoadingSize &&
            !isLoadingPreview &&
            !!criteriaAssets &&
            !isEqual(criteriaAssets, estimateFields)
        ) {
            setLastStoredCriteriaAssets(criteriaAssets);
            saveEstimate({
                audience_id: audienceId,
                shape_id: shapeId,
                ...criteriaAssets,
            });
        }
    }, [audience, criteriaAssets, isLoadingSize, isLoadingPreview, summariesLoading, summariesExpanding]);

    useEffect(() => {
        const SECONDS_MILLI_5 = 1000 * 5;
        let intervalId: string | number | NodeJS.Timeout | undefined;

        if (isLoadingSomeEstimateAsset) {
            intervalId = setInterval(() => {
                const limitStartToFast = new Date().getTime() - SECONDS_MILLI_5;
                const tracker = safeRequestIdTracker.current;
                if (
                    (tracker.size > 0 && tracker.size < limitStartToFast) ||
                    (tracker.preview > 0 && tracker.preview < limitStartToFast) ||
                    Object.values(tracker.summaries).some(s => s > 0 && s < limitStartToFast)
                ) {
                    setIsSlowLoading(true);
                }
            }, 1000);
        } else {
            setIsSlowLoading(false);
        }

        return () => clearInterval(intervalId);
    }, [isLoadingSomeEstimateAsset]);

    useEffect(() => console.log(isSlowLoading), [isSlowLoading]);

    return {
        size,
        preview,
        summaries,
        isLoadingSomeEstimateAsset,
        isLoadingSomeSummary,
        isLoadingSize,
        isLoadingPreview,
        summariesLoading,
        summariesExpanding,
        criteriaAssets,
        isExcluding,
        isSlowLoading,
        callEstimatePreview,
        callAllEstimates,
        expandSummary,
    };
};
