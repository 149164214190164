import { capitalize, isEmpty, isNil } from "lodash";

import { AudienceSync, AudienceSyncStatus } from "@/types/api";
import { formatTimeToShortNotation, getTimeDifference } from "@/utils/date";

import { RunEvent } from "./RunEvent";
import { DurationTimer } from "./DurationTimer";
import Markdown from "markdown-to-jsx";
import { Fragment } from "react";

type Props = {
    sync: AudienceSync;
    audienceName: string;
};

const SyncEvent = ({ sync, audienceName }: Props) => {
    const { createdAt, finishedAt, status, destinationAudienceId, destination, error, adAudienceUrl } = sync;
    const destinationName = capitalize(destination.replace("facebook", "meta"));

    const duration = isNil(finishedAt) ? (
        <DurationTimer startTime={new Date(createdAt)} />
    ) : (
        formatTimeToShortNotation(getTimeDifference(new Date(createdAt), new Date(finishedAt)))
    );

    const details =
        status === AudienceSyncStatus.FINISHED ? (
            <span>
                <span>
                    Sync{!isEmpty(error) ? " failed" : ""} to {destinationName} Audience{" "}
                </span>
                <span className="font-medium">"{audienceName}", </span>
                {destinationAudienceId && (
                    <>
                        <span>ID: </span>
                        <span className="font-medium">"{destinationAudienceId}"</span>
                    </>
                )}
                {!isEmpty(error) && (
                    <p className="text-error">
                        <Markdown
                            children={error!}
                            options={{
                                wrapper: Fragment,
                                overrides: { a: { props: { target: "_blank", className: "underline" } } },
                            }}
                        ></Markdown>
                    </p>
                )}
            </span>
        ) : (
            <span className="text-ui-300">Running</span>
        );

    return (
        <RunEvent
            eventName={`Sync to ${destinationName}`}
            duration={duration}
            details={details}
            success={!isEmpty(finishedAt) && isEmpty(error)}
            inProgress={isEmpty(error) && isEmpty(finishedAt)}
            error={error}
            adAudienceUrl={adAudienceUrl}
        />
    );
};

export default SyncEvent;
