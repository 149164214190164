import { api } from "@/api";
import {
    GetEstimatePreviewRequest,
    GetEstimatePreviewRawResponse,
    GetEstimateSizeRawResponse,
    GetEstimateSizeRequest,
    GetEstimateSummaryRequest,
    GetEstimateSummaryResponse,
    SaveEstimateSummaryRequest,
} from "@/types/api";
import queryString from "query-string";

export const audiencesApiSlice = api.injectEndpoints({
    endpoints: builder => ({
        getCriteriaEstimateSize: builder.mutation<GetEstimateSizeRawResponse, GetEstimateSizeRequest>({
            query: params => {
                const serializedQuery = queryString.stringify(params, { arrayFormat: "comma" });
                return `/criterias/estimate/size?${serializedQuery}`;
            },
        }),
        getCriteriaEstimatePreview: builder.mutation<GetEstimatePreviewRawResponse, GetEstimatePreviewRequest>({
            query: params => {
                const serializedQuery = queryString.stringify(params, { arrayFormat: "comma" });
                return `/criterias/estimate/preview?${serializedQuery}`;
            },
        }),
        getCriteriaEstimateSummary: builder.mutation<GetEstimateSummaryResponse[], GetEstimateSummaryRequest>({
            query: params => {
                const serializedQuery = queryString.stringify(params, { arrayFormat: "comma" });
                return `/criterias/estimate/summary?${serializedQuery}`;
            },
        }),
        saveEstimate: builder.mutation<void, SaveEstimateSummaryRequest>({
            query: ({ audience_id, shape_id, ...body }) => ({
                url: `/criterias/estimate?audience_id=${audience_id}&shape_id=${shape_id}`,
                method: "PATCH",
                body,
            }),
        }),
    }),
});

export const {
    usePrefetch,
    useGetCriteriaEstimateSizeMutation,
    useGetCriteriaEstimatePreviewMutation,
    useGetCriteriaEstimateSummaryMutation,
    useSaveEstimateMutation,
} = audiencesApiSlice;
