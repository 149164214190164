import { summaryCategoriesOrdered } from "@/hooks/useSummary";
import SummaryBox from "@/components/organisms/SummaryBox/SummaryBox";
import Skeleton from "@/components/atoms/Skeleton/Skeleton";
import { ExclusionHookResponse } from "@/hooks/useExclusionStateManager";
import { CriteriaEstimateManagerHook } from "@/hooks/useCriteriaAssetsManager";
import { ExtraChartSchema, SummaryColumnName } from "@/types/audience";

interface SummaryCardProps {
    criteriaAssetsManager: CriteriaEstimateManagerHook;
    exclusionStateManager: ExclusionHookResponse;
}

export const SummaryCard = ({
    criteriaAssetsManager,
    exclusionStateManager,
}: SummaryCardProps) => {

    const generateLoadingSkeleton = (summary: string, limit: number = 15) => {
        const namesLoadingWidths = Array.from({ length: 3}).map(() => ([80, 150, 130, 90, 160])).flat();
        const percentageLoadingWidths = Array.from({ length: 3}).map(() => ([30, 35, 40, 38, 32])).flat();
        return (
            <div key={`loading`}>
                <div className="mb-[8px] font-bold uppercase text-xs text-ui-700">
                    {summary}
                </div>
                <div className="border b-1 rounded-md border-ui-100 p-[4px] h-[278px] overflow-hidden pr-[12px]">
                    <div className="w-full flex flex-col gap-[2px]">
                        {Array.from({ length: limit }).map((i, index) => (
                            <div className="flex h-[32px] items-center" key={i?.toString()}>
                                <Skeleton className={`h-4 rounded-xl bg-gray-300`} style={{ width: `${namesLoadingWidths[index]}px` }} />
                                <Skeleton
                                    className={`h-4 rounded-xl ml-auto bg-gray-300`}
                                    style={{ width: `${percentageLoadingWidths[index]}px` }}
                                />
                                <Skeleton
                                    className={`h-4 ml-3 mr-2 rounded-xl w-4 bg-gray-300`}
                                />
                            </div>)
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const getColumnField = (category?: string) => {
        switch(category) {
            case "company_location_country":
            case "company_location_state":
            case "company_location_city":
                return SummaryColumnName.COMPANY_LOCATION;
            default:
                return category ?? "";
        }
    }

    const isLoadingCategory = (summary: ExtraChartSchema | undefined) => {
        return !summary?.category || (criteriaAssetsManager?.summariesLoading?.includes(getColumnField(summary.category)) && !criteriaAssetsManager.isExcluding);
    }

    const isExpandingCategory = (summary: ExtraChartSchema | undefined) => {
        return !summary?.category || criteriaAssetsManager?.summariesExpanding?.includes(getColumnField(summary.category));
    }

    const expand = async (summary: ExtraChartSchema | undefined) => {
        if (!summary?.category) return;

        await criteriaAssetsManager?.expandSummary({
            category: summary.category as SummaryColumnName,
        })
    }

    return (
        <div className="p-[16px] px-[8px] grid grid-cols-3 group-[.xs-panel]/panel:grid-cols-1 group-[.sm-panel]/panel:grid-cols-2 group-[.md-panel]/panel:grid-cols-3 group-[.lg-panel]/panel:grid-cols-4 group-[.xl-panel]/panel:grid-cols-5 gap-8">
            {summaryCategoriesOrdered?.map((category) => {
                const summary = criteriaAssetsManager.summaries.find(s => s.name === category);
                return (<div key={category} className="static">
                    {(!summary || isLoadingCategory(summary)) && generateLoadingSkeleton(category, summary?.limit)}
                    {summary && !isLoadingCategory(summary) && <SummaryBox
                        allowExpand={summary.allowExpand}
                        isExpanding={isExpandingCategory(summary)}
                        expand={() => expand(summary)}
                        summary={summary!}
                        exclusionStateManager={exclusionStateManager} />}
                </div>
                )
            })}
        </div>);
};
