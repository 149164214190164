import { ExtraChartSchema } from "@/types/audience";
import { uniqWith, isEqual } from "lodash";
import React, { useEffect } from "react";

export interface ExclusionHookResponse {
    updateSelected: (items: SelectedForExclusionItem[]) => Promise<void>;
    exclusionState: ExclusionState;
    runExclude: () => Promise<void>;
    isExcluding: boolean;
    isTimerRunning: boolean;
    setIsTimerRunning: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface SelectedForExclusionItem {
    category: string;
    value: string;
    label?: string;
}

export interface ExclusionState {
    pending: SelectedForExclusionItem[];
    running: SelectedForExclusionItem[];
    queue: boolean[];
    reseted: boolean;
}

interface ExclusionStateManagerParams {
    runExcludeCallback: (items: SelectedForExclusionItem[]) => Promise<void>;
    summaryValues: ExtraChartSchema[];
}
export const useExclusionStateManager = ({
    runExcludeCallback,
    summaryValues,
}: ExclusionStateManagerParams): ExclusionHookResponse => {
    const [exclusionState, setExclusionState] = React.useState<ExclusionState>({
        pending: [],
        running: [],
        queue: [],
        reseted: false,
    });
    const [isTimerRunning, setIsTimerRunning] = React.useState<boolean>(false);

    const isExcluding = React.useMemo(() => exclusionState.queue.length > 0, [exclusionState.queue]);

    useEffect(() => {
        const allPossibleExclusions = summaryValues?.flatMap(t =>
            t.values.map(v => ({
                category: t.category ?? t.name!,
                value: v.key!,
                label: v.label,
            })),
        );
        const newValue = {
            ...exclusionState,
            pending: exclusionState.pending.filter(p => allPossibleExclusions.some(e => isEqual(e, p))),
        };
        if (!isEqual(newValue, exclusionState)) setExclusionState(newValue);
    }, [summaryValues, exclusionState]);

    const startNewExclusion = () =>
        setExclusionState(exclusionState => ({
            pending: [],
            reseted: false,
            running: uniqWith([...exclusionState.running, ...exclusionState.pending], isEqual),
            queue: [...exclusionState.queue, true],
        }));

    const finishExclusion = () =>
        setExclusionState(exclusionState => ({
            ...exclusionState,
            running: exclusionState.queue.length === 1 ? [] : exclusionState.running,
            queue: exclusionState.queue.slice(1),
        }));

    const updateSelected = async (items: SelectedForExclusionItem[]) => {
        setExclusionState(exclusionState => ({
            ...exclusionState,
            pending: items,
            reseted: exclusionState.reseted || items.length > 0,
        }));
    };

    const runExclude = async () => {
        startNewExclusion();
        await runExcludeCallback([...exclusionState.pending]);
        finishExclusion();
    };

    return {
        updateSelected,
        exclusionState,
        runExclude,
        isExcluding,
        isTimerRunning,
        setIsTimerRunning,
    };
};
