import { CriteriaCard } from "@/pages/AudienceNew/CriteriaCard.tsx";
import { AudienceEstimateCard } from "@/pages/AudienceNew/AudienceEstimateCard.tsx";
import { AudienceName } from "@/components/molecules/AudienceName/AudienceName.tsx";
import { DestinationCard } from "@/pages/AudienceNew/DestinationCard.tsx";
import { useLocation, useNavigate, useParams } from "react-router";
import {
    useCreateAudienceMutation,
    useCreateAudienceShapeMutation,
    useFavoriteAudienceMutation,
    useGetAudienceQuery,
    useRunAudienceMutation,
    useUpdateAudienceMutation,
} from "@/api/audiences.ts";
import {
    AudienceStatus as AudienceStatusEnum,
    Destination,
    DestinationsSchema,
    SourceCriteriaFilter,
} from "@/types/audience";
import AudienceHeaderCTA from "@/pages/AudienceNew/AudienceHeaderCTA.tsx";
import ArrowLeftIcon from "@/assets/icons/arrow-left.svg?react";
import { Button } from "@/components/atoms/Button/Button.tsx";
import { AccessibleIcon } from "@radix-ui/react-accessible-icon";
import AudienceStatus from "@/components/molecules/AudienceStatus/AudienceStatus.tsx";
import AudienceFavorite from "@/components/molecules/AudienceFavorite/AudienceFavorite.tsx";
import { useEffect, useMemo, useRef, useState } from "react";
import isNil from "lodash/isNil";
import { checkIfEstimateWillRemaingSame, getNewAudienceName } from "@/utils/audience.ts";
import {
    AudiencesEnrichmentCriteria,
    CreateAudienceRequest,
    CreateAudienceResponse,
    CreateAudienceShapeRequest,
    CreateAudienceShapeResponse,
    FavoriteAudienceRequest,
    RunAudienceRequest,
    UpdateAudienceRequest,
} from "@/types/api";
import ErrorMessage from "@/components/molecules/ErrorMessage/ErrorMessage";
import { useToast } from "@/components/atoms/Toast/useToast.tsx";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { getHttpStatusCode } from "@/api";
import compact from "lodash/compact";
import { Filter, FilterEntityTypes, GroupLogicalOperator } from "@primer/filters/types";
import { useGetConnectionsHash } from "@/hooks/useGetConnectionsHash";
import { activeDestinations } from "@/constants/audiences";
import postHog from "posthog-js";
import { AudiencesInfoBar } from "@/components/organisms/audiences/AudiencesInfoBar";
import { useGetCrmMatchRatesQuery } from "@/api/crm";
import { AppState } from "@/store";
import { useDispatch, useSelector } from "react-redux";
import { setDestinations, setName } from "@/store/audience";
import { useNavigation } from "@/context/NavigationContext";

export const AudienceNew = () => {
    const dispatch = useDispatch();
    const { toast } = useToast();
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const isNewAudience = isNil(id);
    const { getLastDashboardLocation } = useNavigation();
    const lastDashboardLocation = getLastDashboardLocation();
    const {
        data: audienceFromAPI,
        isLoading,
        error: getError,
    } = useGetAudienceQuery(
        { id },
        {
            skip: isNewAudience,
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
            refetchOnFocus: true,
            pollingInterval: 30000,
        },
    );
    const [connectionsHash] = useGetConnectionsHash();
    const [createAudienceMutation, { error: createAudienceError }] = useCreateAudienceMutation();
    const [createAudienceShapeMutation, { isLoading: isLoadingCreateAudienceShape }] = useCreateAudienceShapeMutation();
    const [updateAudienceMutation, { isLoading: isUpdating, error: updateAudienceError }] = useUpdateAudienceMutation();
    const [favoriteAudienceMutation, { error: favoriteAudienceError }] = useFavoriteAudienceMutation();
    const [runAudienceMutation, { isLoading: isBuilding, error: buildAudienceError }] = useRunAudienceMutation();

    const [audienceType] = useState(FilterEntityTypes.PERSON);
    const showLoading = isLoading && location.state?.from !== "/audiences/new";

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const audience = useMemo(
        () => audienceFromAPI ?? location.state?.audience,
        [audienceFromAPI, location.state, location.pathname],
    );
    const audienceFiltersRef = useRef(audience?.shape?.source_criteria?.group?.filters);

    const crmInstanceId = audience?.shape?.source_criteria?.group?.filters.find(
        (f: SourceCriteriaFilter) => !!f.instanceId,
    )?.instanceId;

    const { data: crmMatchRates } = useGetCrmMatchRatesQuery(
        { instanceId: crmInstanceId },
        {
            skip: !crmInstanceId,
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
            refetchOnFocus: true,
            pollingInterval: 30000,
        },
    );

    const [estimateAudience, setEstimateAudience] = useState(audience);
    const [isSummaryLoading, setIsSummaryLoading] = useState(false);
    const [favorite, setFavorite] = useState(audience?.isFavorite ?? false);
    const [showEstimateLoading, setShowEstimateLoading] = useState(false);

    const { name, destinations } = useSelector((state: AppState) => state["audience"]);

    useEffect(() => {
        postHog.capture("Audience page viewed", { audienceId: id, newAudience: isNewAudience });

        dispatch(setName(isNewAudience ? getNewAudienceName() : audience?.name));
    }, []);

    // Select all connected destinations by default for a new audience
    useEffect(() => {
        if (!isNewAudience) return;

        const connectedDestinations: DestinationsSchema = {};
        Object.keys(connectionsHash).forEach(c => {
            const connection = connectionsHash[c as Destination];

            if (connection && activeDestinations.includes(c as Destination)) {
                connectedDestinations[c as Destination] = true;
            }
        });

        dispatch(setDestinations(connectedDestinations));
    }, [connectionsHash]);

    useEffect(() => {
        if (audienceFromAPI) window.history.replaceState({}, "", location.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audienceFromAPI]);

    useEffect(() => {
        if (audience) {
            audienceFiltersRef.current = audience?.shape?.source_criteria?.group?.filters;
            setEstimateAudience(audience);
            setFavorite(audience.isFavorite);
            dispatch(setName(audience.name));
            if (audience.destinations) dispatch(setDestinations(audience.destinations));
        }
    }, [audience]);

    useEffect(() => {
        if (!getError) return;

        const fetchBaseQueryError = getError as FetchBaseQueryError;
        const statusCode = getHttpStatusCode(getError);

        toast({ description: fetchBaseQueryError.data as string, variant: "destructive" });

        if (statusCode && statusCode < 500) navigate("/audiences");
    }, [toast, getError, navigate]);

    useEffect(() => {
        const errors = compact([createAudienceError, updateAudienceError, favoriteAudienceError, buildAudienceError]);

        errors.forEach(e => {
            const fetchBaseQueryError = e as FetchBaseQueryError;
            const defaultError = "An error occurred. Please retry.";
            toast({ description: (fetchBaseQueryError.data as string) || defaultError, variant: "destructive" });
        });
    }, [
        toast,
        getError,
        navigate,
        createAudienceError,
        updateAudienceError,
        favoriteAudienceError,
        buildAudienceError,
    ]);

    useEffect(() => {
        if (!isLoadingCreateAudienceShape) setShowEstimateLoading(false);
    }, [isLoadingCreateAudienceShape]);

    /**
     * If there is a previous state in the history stack, go back to the previous state
     * If there is no previous state in the history stack (user opened this page directly), go back to the dashboard
     */

    const handleBackClick = () => {
        if (lastDashboardLocation?.pathname) {
            navigate(`${lastDashboardLocation?.pathname}${lastDashboardLocation?.search}`);
        } else {
            navigate("/audiences");
        }
    };

    const createAudience = async (req: CreateAudienceRequest): Promise<CreateAudienceResponse> =>
        createAudienceMutation(req).unwrap();

    const createAudienceShape = async (req: CreateAudienceShapeRequest): Promise<CreateAudienceShapeResponse> =>
        createAudienceShapeMutation(req).unwrap();

    const updateAudience = async (req: UpdateAudienceRequest): Promise<CreateAudienceResponse> =>
        updateAudienceMutation(req).unwrap();

    const favoriteAudience = async (req: FavoriteAudienceRequest): Promise<null> =>
        favoriteAudienceMutation(req).unwrap();

    const runAudience = async (req: RunAudienceRequest): Promise<null> => runAudienceMutation(req).unwrap();

    const requestCreatedAudience = async (requestBody: CreateAudienceRequest) => {
        const newAudience = await createAudience(requestBody);
        if (newAudience)
            navigate(`/audiences/${newAudience.id}`, {
                replace: true,
                state: {
                    from: location.pathname,
                    audience: newAudience,
                },
            });

        return newAudience.id;
    };
    const handleNameChange = async (name: string) => {
        dispatch(setName(name));
        if (isNewAudience) {
            await requestCreatedAudience({ name, destinations });
        } else {
            await updateAudience({ id, name });
        }
    };

    const handleFavorite = async (favorite: boolean) => {
        setFavorite(favorite);
        if (id) await favoriteAudience({ audienceId: id, favorite });
    };

    const handleDestinationChange = async (newSelectedDestinations: DestinationsSchema) => {
        dispatch(setDestinations(newSelectedDestinations));
        const newProperties = { name, destinations: newSelectedDestinations };
        if (isNewAudience) {
            await requestCreatedAudience(newProperties);
        } else {
            await updateAudience({ id, destinations: newSelectedDestinations });
        }
    };

    const updateShowEstimateLoading = (
        currentFilters: SourceCriteriaFilter[],
        newFilters: SourceCriteriaFilter[],
    ): boolean => {
        const estimateWontChange = checkIfEstimateWillRemaingSame(currentFilters, newFilters);

        setShowEstimateLoading(showEstimateLoading || !estimateWontChange);
        return showEstimateLoading || !estimateWontChange;
    };

    const saveCriteria = async (filters: Filter[]) => {
        const currentAudienceFilters = audienceFiltersRef.current;
        const source_criteria = {
            target_entity_type: audienceType,
            group: {
                operator: GroupLogicalOperator.AND,
                filters,
                groups: [],
            },
        };
        if (isNewAudience) {
            if (source_criteria?.group?.filters?.some(f => !f.isDuplicated && !f.isEmpty && !f.customError)) {
                setShowEstimateLoading(true);
            }
            await requestCreatedAudience({
                name,
                source_criteria,
                destinations,
            });
        } else {
            const updateEstimate = updateShowEstimateLoading(currentAudienceFilters ?? [], filters ?? []);
            audienceFiltersRef.current = filters;
            const estimateResponse = await createAudienceShape({
                audienceId: id,
                enrichment_criteria: {
                    categories: [AudiencesEnrichmentCriteria.AD],
                },
                source_criteria,
                updateEstimate,
            });
            setEstimateAudience(estimateResponse);
        }
    };

    const CriteriaCardMemo = useMemo(() => {
        return (
            <CriteriaCard
                sourceCriteria={audience?.shape?.source_criteria}
                status={audience?.status}
                audienceType={audienceType}
                showCriteriaError={false}
                saveCriteria={saveCriteria}
            />
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audience?.status, saveCriteria]);

    return (
        <>
            <header className="py-6 px-12 m-1.5 bg-white rounded-2xl shadow-main sticky top-0 z-10">
                <div className="flex-none flex items-center justify-between">
                    <div className="flex items-center flex-1 overflow-hidden">
                        <Button
                            variant="secondary"
                            size="icon"
                            className="mr-4 !h-9"
                            title="Back"
                            onClick={handleBackClick}
                        >
                            <AccessibleIcon label="Back">
                                <ArrowLeftIcon />
                            </AccessibleIcon>
                        </Button>
                        <AudienceStatus
                            loading={showLoading}
                            status={audience?.status || AudienceStatusEnum.DRAFT}
                            className="mr-2"
                        />
                        <AudienceFavorite
                            favorite={favorite}
                            disabled={isNewAudience}
                            buttonClassName="mr-2"
                            onAudienceFavorite={handleFavorite}
                        />
                        <AudienceName
                            loading={showLoading}
                            name={name}
                            onNameChange={handleNameChange}
                            disabled={false}
                        />
                    </div>

                    <AudiencesInfoBar />

                    <AudienceHeaderCTA
                        audience={audience}
                        loading={showLoading}
                        isBuilding={isBuilding}
                        isUpdating={isUpdating}
                        isLoadingEstimateInfo={showEstimateLoading || isSummaryLoading}
                        status={audience?.status || AudienceStatusEnum.DRAFT}
                        audienceId={id}
                        runAudience={runAudience}
                        // lastUpdatedAt={audience?.lastUpdatedAt}
                        submittedAt={audience?.submittedAt}
                    />
                </div>
                {audience?.hasError && (
                    <div className="mt-4">
                        <ErrorMessage error="General audience error" />
                    </div>
                )}
            </header>
            <div className="px-1.5 pt-2 pb-10 md:px-12 flex-grow gap-4 flex justify-center">
                <div className="[&>*]:mb-2 max-w-[1100px] w-full">
                    <DestinationCard
                        loading={showLoading}
                        disabled={
                            audience?.status === AudienceStatusEnum.ARCHIVED ||
                            audience?.status === AudienceStatusEnum.RUNNING
                        }
                        selectedDestinations={destinations}
                        setSelectedDestinations={handleDestinationChange}
                    />
                    {/* <AudienceTypeCard audienceType={audienceType} setAudienceType={setAudienceType} /> */}
                    {CriteriaCardMemo}
                </div>
                <div className="sticky top-[100px] h-fit ">
                    <AudienceEstimateCard
                        audience={estimateAudience}
                        isLoadingNewEstimate={showEstimateLoading}
                        setIsSummaryLoading={setIsSummaryLoading}
                        crmMatchRates={crmMatchRates}
                    />
                </div>
            </div>
        </>
    );
};
